import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { InOntwikkeling } from '../components/InOntwikkeling';
import { Fade } from "react-slideshow-image";

const slideImages = ["/img/knutselen2.jpg", "/img/playmobiel.jpg"];

export class Financiering extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Ashantinez | Aanbod</title>
                    <meta name="description" content="." data-react-helmet="true" />
                    <meta property="og:title" content="." data-react-helmet="true" />
                    <meta property="og:description" content="." data-react-helmet="true" />
                    <meta property="og:url" content="https://ashantinez.nl/financiering-verwijzing" data-react-helmet="true" />
                </Helmet>
                <Container>
                    <div className="inner">
                        <h1>Financiering en verwijzing</h1>
                        <Row>
                            <Col xs="12" md="8">
                                <p>Na uw aanmelding volgt er eerst een vrijblijvend kennismaking gesprek. We bekijken met u wat uw persoonlijke vragen en wensen zijn. Afhankelijk van dit gesprek zal blijken of Ashantinez iets voor u kan betekenen.</p>
                                <p>De begeleiding start wanneer we samen besluiten dat Ashantinez de juiste hulp kan bieden. We werken vraaggericht. Dat wil zeggen dat we samen met u bekijken wat uw hulpvraag is. Daarna proberen we een passend aanbod te doen. Afhankelijk van de aard van uw vraag is het soms nodig een verwijzing aan te vragen bij uw huisarts.</p>
                                <p>U kunt zich als particulier bij ons aanmelden hiervoor rekenen wij een uurtarief van 125,- euro. Wij maken een factuur voor u op voor de geleverde diensten. Met een verwijzing van de huisarts kunnen in sommige gevallen uw kosten vergoed worden.</p>
                                <p><strong>Verwijsbrief: </strong><Link to="/Verwijsbrief-Ashantinez.doc" target="_blank">klik hier</Link></p>
                            </Col>
                            <Col xs="12" md="4">
                                <Fade>
                                    <img src={slideImages[0]} />
                                    <img src={slideImages[1]} />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </Container></>
        );


    }
}