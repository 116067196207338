import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Col, Container, NavItem, Row } from 'reactstrap';
import './Footer.scss';

export class Footer extends Component {
    render() {
        return (
            <><footer>
                <Container>
                    <div className="inner">
                        <Row>
                            <Col xs="12" md="4">
                                <h3>Meer over...</h3>
                                <ul className="navbar-nav flex-grow">
                                    <NavItem><NavLink to="/over-ons">Ashantinez</NavLink></NavItem>
                                    <NavItem><NavLink to="/aanbod">Ons aanbod</NavLink></NavItem>
                                    <NavItem><NavLink to="/financiering-verwijzing">Financiering en verwijzing</NavLink></NavItem>
                                    <NavItem><NavLink to="/contact">Contact</NavLink></NavItem>
                                </ul>
                            </Col>
                            <Col xs="12" md="4">
                                <h3>Aanbod</h3>
                                <ul className="navbar-nav flex-grow">
                                    <NavItem><NavLink to="/aanbod#iag-gezinsbehandeling">IAG gezinsbehandeling</NavLink></NavItem>
                                    <NavItem><NavLink to="/aanbod#opvoedingsondersteuning">Opvoedingsondersteuning</NavLink></NavItem>
                                    <NavItem><NavLink to="/aanbod#socialevaardigheidstraining">Socialevaardigheidstraining</NavLink></NavItem>
                                    <NavItem><NavLink to="/aanbod#weerbaarheidstraining">Weerbaarheidstraining</NavLink></NavItem>
                                    <NavItem><NavLink to="/aanbod#systemischcoachen">Systemisch coachen</NavLink></NavItem>
                                </ul>
                            </Col>
                            <Col xs="12" md="4">
                                <h3>Contact</h3>
                                <p>Postadres Inez Dakriet<br />Gooise Kant 222<br />1104 MM Amsterdam<br />Tel. <Link to="bel">06 44678526</Link><br /><Link to="/stuurmail">info@ashantinez.nl</Link></p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </footer></>
        );
    }
}
