import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './BannerSlogan.scss';

export class InOntwikkeling extends Component {
    render() {
        return (
            <>
                <Container>
                    <div className="inner">
                        <h1>Ashantinez Opvoeding Begeleiding en Coaching</h1>
                        <Row>
                            <Col xs="12" md="12">
                                <p>Deze website is nog in de maak. We zien u graag spoedig terug!</p>
                            </Col>
                        </Row>
                    </div>
                </Container></>
        );
    }
}
