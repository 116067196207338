import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { InOntwikkeling } from '../components/InOntwikkeling';
import { ContactForm } from '../components/ContactForm';

export class Contact extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Ashantinez | Aanbod</title>
                    <meta name="description" content="." data-react-helmet="true" />
                    <meta property="og:title" content="." data-react-helmet="true" />
                    <meta property="og:description" content="." data-react-helmet="true" />
                    <meta property="og:url" content="https://ashantinez.nl/contact" data-react-helmet="true" />
                </Helmet>
                <Container>
                    <div className="inner">
                        <h1>Contact met Ashantinez</h1>
                        <Row>
                            <Col xs="12" md="6">
                                <p>U kunt zelf contact met ons opnemen voor een vrijblijvend eerste gesprek.</p>
                                <Row>
                                    <Col xs="12" md="6">
                                        <div>
                                            <iframe width="100%" height="250px" id="gmap_canvas" src="https://maps.google.com/maps?q=Gooise%20Kant%20222%201104%20MM%20Amsterdam&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                        </div>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <p><strong>Postadres Inez Dakriet</strong>
                                            <br />Gooise Kant 222
                                            <br />1104 MM Amsterdam
                                            <br />Tel. <Link to="bel">06 44678526</Link>
                                            <br /><Link to="stuurmail">info@ashantinez.nl</Link><br /><br />
                                        </p>
                                    </Col>
                                </Row>
                                <p><br/><strong>Registratie codes</strong></p>
                                <p>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>AGB-register</td>
                                                <td>90057344</td>
                                            </tr>
                                            <tr>
                                                <td>SKJ kwaliteitsregister</td>
                                                <td>140027317</td>
                                            </tr>
                                            <tr>
                                                <td>BPSW<br/>(Beroepsvereniging Professional Social Work)</td>
                                                <td>626844</td>
                                            </tr>
                                            <tr>
                                                <td>SoloPartners</td>
                                                <td>123759</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </p>
                            </Col>
                            <Col xs="12" md="6">
                                {<ContactForm />}
                            </Col>
                        </Row>
                    </div>
                </Container></>
        );
    }
}