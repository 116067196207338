import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import { InOntwikkeling } from '../components/InOntwikkeling';

export class Aanbod extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Ashantinez | Aanbod</title>
                    <meta name="description" content="." data-react-helmet="true" />
                    <meta property="og:title" content="." data-react-helmet="true" />
                    <meta property="og:description" content="." data-react-helmet="true" />
                    <meta property="og:url" content="https://ashantinez.nl/aanbod" data-react-helmet="true" />
                </Helmet>
                <Container>
                    <div className="inner">
                        <h1>Aanbod</h1>
                        <Row>
                            <Col xs="12" md="12">
                                <h2 id="iag-gezinsbehandeling">IAG GEZINSBEHANDELING<span>Intensieve Ambulante Gezinsbehandeling</span></h2>
                                <p>IAG is gericht op het verminderen van gedragsproblemen van kinderen en jongeren, het vergroten van de opvoedvaardigheden van ouders en het meer betrekken van het sociaal netwerk van het gezin. In deze vorm van begeleiding wordt het hele gezin betrokken. Afgezien dat ieder individueel gezinslid zijn eigen persoonlijke vraagstukken heeft, vormt het gezin een geheel. <strong>Er wordt gekeken naar ieder zijn individuele vraag. Vervolgens is de vraag waar zit ieder zijn individuele kracht. Wat houdt hem of haar op de been. Wat houdt het gezin op de been.</strong></p>
                                <p>De behandeling richt zich op de stip op de horizon. Hoe zien de oplossingen er voor het gezin in de toekomst uit. We gaan samen kijken welke kleine stapjes we in die richting kunnen zetten. Daarna richt de begeleiding zich op, wat het gezin nodig heeft om weer een geheel te kunnen vormen. De behandeling vindt plaats in drie fasen. De startfase, de veranderfase en de afbouwfase.</p>

                                <div className="spacer"></div>

                                <h2 id="opvoedingsondersteuning">OPVOEDINGS ONDERSTEUNING<span>Begeleiding aan ouder en kind</span></h2>
                                <p>Opvoeding gaat niet vanzelf. Er is niet een goede manier van opvoeden. Maatwerk is onze insteek bij het begeleiden van ouders en verzorgers. De ondersteuning is positief gericht. We ondersteunen ouders positief op te voeden.</p>
                                <p>De vragen kunnen gaan over de ontwikkeling van jong kinderen en pubers. Samen met ouders zoeken wij naar een oplossingen, geven handvatten, zodat ze zelf weer verder kunnen. We ondersteunen ouders ook in het zorgen voor zichzelf. Als ouders goed voor zichzelf zorgen, houden ze het positief opvoeden langer vol.</p>

                                <div className="spacer"></div>

                                <h2 id="socialevaardigheidstraining">SOCIALEVAARDIGHEIDSTRAINING (inclusief workshops)<span>Kinderen van 4-12 jaar</span></h2>
                                <p>Ashantinez socialevaardigheidstraining wordt gecombineerd met creatieve activiteiten. Naast vaardigheden, die je kunnen helpen je beter te voelen, kunnen wij je ook leren beter om te gaan met anderen. Door creatief bezig te zijn tijdens de traning leren de kinderen ook positieve gevoelens te ervaren.</p>
                                <p>We maken een start met het thema "<strong>Wie ben ik</strong>". Met deze vraag gaan wij aan de slag. Op een speelse manier leer je zelf beter kennen, waardoor je zelfbeeld en zelfvertrouwen vergroot wordt. Dit thema wordt gekoppeld aan een creatieve activiteit of een spel.</p>
                                <p>Thema's die onder andere aan de orde komen zijn:</p>
                                <ul>
                                        <li>Omgaan met pesten.</li>
                                        <li>Zelfbeeld vergroten</li>
                                        <li>Omgaan met emoties (blij, boos, angst, verdriet)</li>
                                        <li>Omgaan met conflicten.</li>
                                        <li>De trainingen kunnen individueel als in groepsverband gegeven worden.</li>
                                </ul>
                                <h3>Activiteiten en workshops</h3>
                                <p>De creatieve activiteiten lopen uiteen. Denk hierbij aan schilderen, zandtekeningen maken of een spelletje doen. Ashantinez biedt twee workshops aan:</p>
                                <ol><li>Zandvazen vullen</li><li>Smily zandtekeningen maken.</li></ol>
                                <p>Groepsgrote 10 personen voor 350,-</p>
                                <p></p>

                                <div className="spacer"></div>

                                <h2 id="weerbaarheidstraining">WEERBAARHEIDS TRAINING<span>Jongeren van 12-21 jaar</span></h2>
                                <p>Op weg naar volwassenheid kunnen er heel veel vragen aan de orde komen. Je gaat steeds zelfstandiger worden en gaat keuzes maken. Dit vraagt de nodige vaardigheden. Met al deze vaardigheden kunnen wij je een handje helpen. Als je met een vraag of probleem zit, leren wij je een plan te maken hoe je dit zou kunnen aanpakken. bv. Hierin word jij zelf als persoon begeleid, maar we kijken ook naar de steunende factoren in je omgeving. Wat wij belangrijk vinden is hoe jij leert stevig in je schoenen te staan binnen de maatschappij.</p>
                                <p>Thema's die aan de orde kunnen komen zijn:
                                    <ul>
                                        <li>Hoe leer je "Nee" zeggen.</li>
                                        <li>Hoe kies jij je vrienden.</li>
                                        <li>Hoe ga je om met jouw emoties</li>
                                        <li>Welke keuzes maak je.</li>
                                        <li>Aan wie kun je steun vragen als ik niet lekker in mijn vel zit.</li>
                                    </ul>
                                </p>

                                <div className="spacer"></div>
                                <h2 id="systemischcoachen">SYSTEMISCH COACHEN<span>Persoonlijke ontwikkeling</span></h2>
                                <p>Iedereen wordt be&#207;nvloed door de systemen waar hij deel van uit maakt. Je familie is zo een systeem maar ook de organisatie waar je voor werkt, sociale omgeving of relaties. Systemisch coachen is een manier om inzicht te krijgen in jouw eigen plek in het systeem, welk systeem dan ook, en je verhoudingen met de anderen in het systeem. Je kunt ervaren waar belemmeringen zitten en waarom je steeds opnieuw vervalt in bepaald gedrag, dat je belemmert in je ontwikkeling en groei. Systemisch coachen kan leiden tot inzicht en acceptatie. Inzichten in hoe je als individu staat ten opzichte van je systeem. ALS JE WEER LIEFDEVOL NAAR JE EIGEN SYSTEEM KUNT KIJKEN, WORDT HET WEER MOGELIJK AUTONOOM TE KIEZEN VOOR JE EIGEN GELUK.</p>
                            </Col>
                        </Row>
                    </div>
                </Container></>
        );
    }
}