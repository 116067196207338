import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { InOntwikkeling } from '../components/InOntwikkeling';
import { Fade } from "react-slideshow-image";

const slideImages = ['/img/Inez2.jpg', '/img/knutselen5.jpeg', '/img/knutselen6.jpeg', '/img/spel3.jpeg'];

export class OverOns extends Component {
  constructor(props) {
      super(props);

      if (props.location.props != null) {
          this.highlightedId = props.location.props.highlightedId;
      }
  }

  render() {
      return (
          <>
              <Helmet>
                  <title>Ashantinez | Aanbod</title>
                  <meta name="description" content="." data-react-helmet="true" />
                  <meta property="og:title" content="." data-react-helmet="true" />
                  <meta property="og:description" content="." data-react-helmet="true" />
                  <meta property="og:url" content="https://ashantinez.nl/aanbod" data-react-helmet="true" />
              </Helmet>
              <Container>
                  <div className="inner">
                      <h1>Over ons</h1>
                      <Row>
                          <Col xs="12" md="4">
                              <Fade>
                                  <img src={slideImages[0]} />
                                  <img src={slideImages[1]} />
                                  <img src={slideImages[2]} />
                                  <img src={slideImages[3]} />
                              </Fade>
                          </Col>
                          <Col xs="12" md="8">
                              <p>Vanuit Ashantinez werken we vanuit een positief uitgangspunt. Vanuit een open niet oordelende en positieve houding sluiten wij aan bij de wensen, ervaringen en belevingen van het gezin. We richten ons op de krachten en mogelijkheden van het gezin en hun netwerk. De hulp is intensief en vindt plaats in de omgeving van het gezin.</p>
                              <p>Ik ben Inez Dakriet. Ik ben oprichtster van Ashantinez begeleiding en Coaching. Met Liefde, een positief gevoel en humor sta ik in het leven. Ik ben maatschappelijk werkster & IAG- Behandelaar (Intensieve Ambulante Gezinsbehandeling). Daarnaast heb ik de opleiding systemisch werken gedaan. Ik ben ruim 20 jaar werkzaam in de hulpverlening.</p>
                              <p>Door de ervaring die ik heb mogen opdoen, ben ik tot de conclusie gekomen dat het allemaal draait om het volgende: <strong>DE KRACHT VINDEN IN JEZELF</strong>.</p>
                              <p>Het kan zijn dat je door omstandigheden het even moeilijk hebt. Met een steuntje in de rug kunnen wij je weer op weg helpen. Ik heb ervaren dat jouw eigen kracht met de juiste inzet en liefde je tot grote steun kunnen zijn. Als persoon heb ik mij, mede door mijn werk en levenservaring, ontwikkeld tot een stimulerende en motiverende coach.</p>
                          </Col>
                      </Row>
                  </div>
              </Container></>
    );
  }
}