import React, { Component } from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { Route, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.scss';
import { Contact } from './Pages/Contact';
import { Home } from './Pages/Home';
import { OverOns } from './Pages/OverOns';
import { Aanbod } from './Pages/Aanbod';
import { Financiering } from './Pages/Financiering';
import { NotFound } from './Pages/NotFound';

export default class App extends Component {
    componentDidMount() {
        loadReCaptcha("6LcZ3OwcAAAAANOoEIChfo8vgJqi11TdfekwDUT9", this.callback());
    }

    callback() {
        // reCaptcha callback if needed.
    }

    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/over-ons' component={OverOns} />
                    <Route path='/aanbod' component={Aanbod} />
                    <Route path='/financiering-verwijzing' component={Financiering} />
                    <Route path='/contact' component={Contact} />
                    <Route path='/stuurmail' render={(props) =>
                        <Contact action="stuurmail" />
                    } />
                    <Route path='/bel' render={(props) =>
                        <Contact action="bel" />
                    } />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}