import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { InOntwikkeling } from '../components/InOntwikkeling';
import { Container, Row, Col } from 'reactstrap';
import 'react-slideshow-image/dist/styles.css'
import { Fade } from "react-slideshow-image";

const slideImages = ['/img/knutselen4.jpg', '/img/spel2.jpeg', '/img/spel1.jpeg'];

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <>
                <Helmet>
                    <title>Ashantinez | Home</title>
                </Helmet>
                <section className="parallax fade-in" style={{ backgroundImage: "url(/img/knutselen3.jpg)", height: "300px" }}>
                    <Container className="bannerContent">
                        <div id="homeBannerText">
                            <div>
                                <ul><li>ASHANTINEZ</li><li>OPVOEDING</li><li>BEGELEIDING</li><li>& COACHING</li></ul>
                            </div>
                        </div>
                    </Container>
                </section>
                <Container>
                    <div className="inner">
                        <h1>Ashantinez Opvoeding Begeleiding en Coaching</h1>
                        <Row>
                            <Col xs="12" md="8">
                                <p>Al meer dan 10 jaar is <strong>Ashantinez Opvoeding begeleiding en coaching</strong> werkzaam in het werkveld met ouders en kinderen.</p>
                                <p>Ashantinez is gespecialiseerd in het begeleiden van kinderen, jeugdigen en gezinnen. Ons hulpaanbod is erop gericht de jeugdige en het gezin weer in zijn kracht te zetten. We werken oplossingsgericht en systeemgericht.</p>
                                <p>We helpen je weer de regie in eigen hand te nemen, op eigen benen te staan en plezier te hebben in de dingen die wel goed gaan. We richten ons op mogelijkheden en de eigen krachten van ieder individu binnen het gezin. Dit brengen we samen in de kracht van het gezin.</p>
                            </Col>
                            <Col xs="12" md="4">
                                <Fade>
                                    <div className="ParallaxVideo">
                                        <video autoPlay muted loop>
                                            <source src="/img/video.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <img src={slideImages[0]} />
                                    <img src={slideImages[1]} />
                                    <img src={slideImages[2]} />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </Container></>
        );
    }
}
