import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import { createBrowserHistory } from 'history';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const history = createBrowserHistory();

history.listen((location) => {
    setTimeout(
        function () {
            var element = null;
            console.log(location.hash);
            if (location.hash == '#iag-gezinsbehandeling') {
                element = document.querySelector("#iag-gezinsbehandeling");
            } else if (location.hash == '#opvoedingsondersteuning') {
                element = document.querySelector("#opvoedingsondersteuning");
            } else if (location.hash == '#socialevaardigheidstraining') {
                element = document.querySelector("#socialevaardigheidstraining");
            } else if (location.hash == '#weerbaarheidstraining') {
                element = document.querySelector("#weerbaarheidstraining");
            } else if (location.hash == '#systemischcoachen') {
                element = document.querySelector("#systemischcoachen");
            } 
            else if (window.innerWidth <= 575) {
                element = document.querySelector("h1");
            } else {
                element = document.querySelector("body");
            }
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 100); // A little delay so the javascript doens't get the position of the previous page

    if (location.pathname === "/bel") {
        window.location.href = "tel:+31644678526";
    } else if (location.pathname === "/stuurmail") {
        window.location.href = "mailto:info@ashantinez.nl";
    }
});

ReactDOM.render(
    <Router basename={baseUrl} history={history}>
        <App />
    </Router>,
    rootElement);